<template>
  <div>
    <KTCard v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          商户管理
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <b-button variant="outline-primary" class="mx-2" @click="goBack()">
            <i class="flaticon2-left-arrow"></i>
            返回
          </b-button>
          <!--按钮组-->
          <b-button variant="primary" @click="onSubmit()" class="mx-2">
            <i class="flaticon2-fax"></i>
            保存
          </b-button>

          <!--按钮组-->
          <b-button variant="danger" @click="onReset()" class="mx-2">
            <i class="flaticon2-circular-arrow"></i>
            重置
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div
          style="
            width: 70% !important;
            min-height: 70vh;
            margin: auto !important;
          "
        >
          <b-form class="form" id="merchantForm">
            <b-row class="mt-1">
              <b-col class="pt-0 pb-2" align-self="center">
                <div
                  class="font-weight-bold"
                  style="flex: 1 1 0%; display: flex"
                >
                  <div style="display: flex; justify-content: center">
                    <i
                      class="flaticon-profile-1 display-2 text-muted font-weight-bold"
                    ></i>
                  </div>
                  <div
                    class="ml-3"
                    style="
                      display: flex;
                      align-self: center;
                      flex-direction: column;
                      font-size: 18px;
                    "
                  >
                    商户基本信息
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="separator separator-dashed mb-5"> </b-row>

            <b-row>
              <b-col class="pt-0 pb-2">
                <b-form-group label="商户名称:">
                  <b-form-input
                    type="text"
                    name="merchantName"
                    v-model="formModel.merchantName"
                    placeholder="请输入商户名称..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="登录账号:">
                  <b-form-input
                    :readonly="editModel"
                    type="text"
                    name="loginAccount"
                    v-model="formModel.loginAccount"
                    placeholder="请输入登录账号..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="appId:">
                  <b-input-group>
                    <b-form-input
                      type="text"
                      disabled
                      readonly
                      name="appKey"
                      v-model="formModel.appId"
                      placeholder="请输入appId..."
                    >
                    </b-form-input>
                    <b-input-group-append v-if="!editModel">
                      <b-button
                        variant="success"
                        @click="generateKey"
                        title="生成应用key与秘钥"
                        >生成</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="登录密码:">
                  <b-input-group>
                    <b-form-input
                      :type="hidePwd ? 'password' : 'text'"
                      name="loginPassword"
                      v-model="formModel.loginPassword"
                      placeholder="默认密码为 123456..."
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        @click="hidePwd = !hidePwd"
                        variant="outline-secondary"
                        title="显示隐藏"
                      >
                        <i
                          :class="hidePwd ? 'far fa-eye' : 'far fa-eye-slash'"
                        ></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="应用公钥:">
                  <b-form-input
                    type="text"
                    name="publicKey"
                    v-model="formModel.publicKey"
                    placeholder="请输入应用公钥..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="应用私钥:">
                  <b-input-group>
                    <b-form-input
                      :type="hideSecret ? 'password' : 'text'"
                      disabled
                      readonly
                      name="privateKey"
                      v-model="formModel.privateKey"
                      placeholder="请输入应用私钥..."
                    >
                    </b-form-input>

                    <b-input-group-append>
                      <b-button
                        size="sm"
                        @click="hideSecret = !hideSecret"
                        variant="outline-secondary"
                        title="显示隐藏"
                      >
                        <i
                          :class="
                            hideSecret ? 'far fa-eye' : 'far fa-eye-slash'
                          "
                        ></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通知邮箱:">
                  <b-form-input
                    type="text"
                    name="email"
                    v-model="formModel.email"
                    placeholder="请输入通知邮箱..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="对账QQ:">
                  <b-form-input
                    type="text"
                    name="qq"
                    v-model="formModel.qq"
                    placeholder="请输入对账QQ..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="联系电话:">
                  <b-form-input
                    type="text"
                    name="telPhone"
                    v-model="formModel.telPhone"
                    placeholder="请输入联系电话..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-0">
                <b-row>
                  <b-col>
                    <b-form-group label="USDT汇率:">
                      <b-form-input
                        type="number"
                        name="usdtRate"
                        v-model="formModel.usdtRate"
                        placeholder="请输入USDT汇率..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="结算方式:">
                      <b-form-select
                        name="settleType"
                        v-model="formModel.settleType"
                      >
                        <option selected>请选择结算方式...</option>
                        <option :value="-2">手动模式</option>
                        <option :value="-1">D+0</option>
                        <option :value="0">T+0</option>
                        <option :value="1">T+1</option>
                        <option :value="7">T+7</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-row>
                  <b-col>
                    <b-form-group label="白名单:">
                      <b-form-textarea
                        type="text"
                        :rows="1"
                        name="whitelists"
                        v-model="formModel.whitelists"
                        placeholder="请输入白名单 多个请用,号隔开..."
                      >
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="pt-0 pb-0">
                <b-row>
                  <b-col>
                    <b-form-group label="tg群ID:">
                      <b-form-input
                        type="text"
                        name="tgChatGroupId"
                        v-model="formModel.tgChatGroupId"
                        placeholder="请输入tg群ID"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col clos="4">
                    <b-form-group label="状态:">
                      <v-radio-group
                        class="mt-0"
                        row
                        name="status"
                        v-model="formModel.status"
                        :mandatory="false"
                      >
                        <v-radio label="正常" :value="1"></v-radio>
                        <v-radio label="禁用" :value="0"></v-radio>
                      </v-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col class="pt-0 pb-2">
                <div
                  class="font-weight-bold"
                  style="flex: 1 1 0%; display: flex"
                >
                  <div style="display: flex; justify-content: center">
                    <i
                      class="flaticon-price-tag display-2 text-muted font-weight-bold"
                    ></i>
                  </div>
                  <div
                    class="ml-3"
                    style="
                      display: flex;
                      align-self: center;
                      flex-direction: column;
                      font-size: 18px;
                    "
                  >
                    提现通道配置
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row class="separator separator-dashed my-5"> </b-row>
            <b-row class="mb-0 mt-0">
              <b-col class="pt-0 pb-2">
                <b-card border-variant="secondary" body-class="m-0 py-0 px-3">
                  <template v-slot:default>
                    <div>
                      <h6 style="flex: 1 1 0%; display: flex">
                        <div style="display: flex; justify-content: center">
                          <span class="switch switch-icon mt-1"> </span>
                        </div>
                        <div
                          style="
                            display: flex;
                            align-self: center;
                            margin-left: 20px;
                          "
                        ></div>
                      </h6>
                    </div>

                    <b-collapse class="my-2" visible>
                      <b-row class="separator separator-dashed mt-1"> </b-row>
                      <b-row>
                        <b-col cols="3" class="border-right my-auto pb-0">
                          <b-row class="m-0 p-0">
                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group label="提现手续费:">
                                <b-form-input
                                  type="number"
                                  name="withdrawFee"
                                  v-model="formModel.withdrawFee"
                                  placeholder="请输入提现手续费..."
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group label="提现费率:">
                                <b-form-input
                                  type="number"
                                  name="withdrawRate"
                                  v-model="formModel.withdrawRate"
                                  placeholder="请输入提现费率..."
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="9" class="my-auto pb-0">
                          <b-row
                            class="m-0 p-0"
                            :key="index"
                            v-for="(
                              channelItem, index
                            ) in formModel.withdrawConfig"
                          >
                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-input
                                  class="mb-2 mr-sm-2"
                                  type="number"
                                  :name="'w_minTradeAmount_' + index"
                                  v-model="channelItem.minTradeAmount"
                                  placeholder="最小金额..."
                                ></b-input>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-input
                                  class="mb-2 mr-sm-2"
                                  type="number"
                                  :name="'w_maxTradeAmount_' + index"
                                  v-model="channelItem.maxTradeAmount"
                                  placeholder="最大金额..."
                                ></b-input>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-form-select
                                  class="mb-2 mr-sm-2"
                                  :name="'w_interfaceId_' + index"
                                  v-model="channelItem.interfaceId"
                                >
                                  <option :value="null" selected>
                                    请选择代付通道...
                                  </option>
                                  <option
                                    :value="infItem.interfaceId"
                                    :key="infItem.interfaceId"
                                    v-for="infItem in interfaces"
                                    :disabled="checkWithdrawAable(infItem)"
                                  >
                                    {{ infItem.channelName }} ({{
                                      infItem.channelAlias
                                    }})
                                  </option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-input-group append="%" class="mb-2 mr-sm-2">
                                  <b-input
                                    :name="'w_tradeRatio_' + index"
                                    v-model="channelItem.tradeRatio"
                                    placeholder="放量比例"
                                  ></b-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-button
                                v-if="index == 0"
                                title="添加"
                                class="mb-2 mr-sm-1 ml-1"
                                variant="primary"
                                size="sm"
                                v-on:click="addWithdrawItem()"
                              >
                                <b-icon icon="plus"></b-icon>
                              </b-button>

                              <b-button
                                v-else
                                title="删除"
                                class="mb-2 mr-sm-1 ml-1"
                                variant="danger"
                                size="sm"
                                v-on:click="deleteWithdrawItem(channelItem)"
                              >
                                <b-icon icon="x"></b-icon>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </template>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col class="pt-0 pb-2">
                <div
                  class="font-weight-bold"
                  style="flex: 1 1 0%; display: flex"
                >
                  <div style="display: flex; justify-content: center">
                    <i
                      class="flaticon2-settings display-2 text-muted font-weight-bold"
                    ></i>
                  </div>
                  <div
                    class="ml-3"
                    style="
                      display: flex;
                      align-self: center;
                      flex-direction: column;
                      font-size: 18px;
                    "
                  >
                    支付通道配置
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row class="separator separator-dashed my-5"> </b-row>

            <b-row
              class="mb-0 mt-0"
              :key="typeItem.payType"
              v-for="typeItem in formModel.payRuleList"
            >
              <b-col class="pt-0 pb-2">
                <b-card border-variant="secondary" body-class="m-0 py-0 px-3">
                  <template v-slot:default>
                    <div>
                      <h6 style="flex: 1 1 0%; display: flex">
                        <div style="display: flex; justify-content: center">
                          <span class="switch switch-icon mt-1">
                            <label>
                              <input
                                type="checkbox"
                                :true-value="1"
                                :false-value="0"
                                v-model="typeItem.status"
                                @change="onPayTypeChange(typeItem)"
                              />
                              <span></span>
                            </label>
                            <span
                              class="text-dark-75 font-weight-bolder ml-3"
                              >{{ typeItem.payTypeName }}</span
                            >
                          </span>
                        </div>
                        <div
                          style="
                            display: flex;
                            align-self: center;
                            margin-left: 20px;
                          "
                        >
                          <b-form-group style="margin-bottom: 0px !important">
                            <input
                              type="checkbox"
                              hidden
                              :name="'status_' + typeItem.payType"
                              v-model="typeItem.status"
                            />
                          </b-form-group>
                        </div>
                      </h6>
                    </div>

                    <b-collapse class="my-2" visible>
                      <b-row class="separator separator-dashed mt-1"> </b-row>
                      <b-row>
                        <b-col cols="3" class="border-right my-auto pb-0">
                          <b-row class="m-0 p-0">
                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group label="费率:">
                                <b-form-input
                                  type="number"
                                  :name="'feeRate_' + typeItem.payType"
                                  v-model="typeItem.feeRate"
                                  placeholder="请输入费率..."
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col cols="9" class="my-auto pb-0">
                          <b-row
                            class="m-0 p-0"
                            :key="index"
                            v-for="(
                              channelItem, index
                            ) in typeItem.channelConfig"
                          >
                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-input
                                  class="mb-2 mr-sm-2"
                                  type="number"
                                  :name="
                                    'minTradeAmount_' +
                                    typeItem.payType +
                                    '_' +
                                    index
                                  "
                                  v-model="channelItem.minTradeAmount"
                                  placeholder="最小金额..."
                                ></b-input>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-input
                                  class="mb-2 mr-sm-2"
                                  type="number"
                                  :name="
                                    'maxTradeAmount_' +
                                    typeItem.payType +
                                    '_' +
                                    index
                                  "
                                  v-model="channelItem.maxTradeAmount"
                                  placeholder="最大金额..."
                                ></b-input>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-form-select
                                  class="mb-2 mr-sm-2"
                                  :name="
                                    'interfaceId_' +
                                    typeItem.payType +
                                    '_' +
                                    index
                                  "
                                  v-model="channelItem.interfaceId"
                                >
                                  <option :value="null" selected>
                                    请选择支付通道...
                                  </option>
                                  <option
                                    :value="infItem.interfaceId"
                                    :key="infItem.interfaceId"
                                    v-for="infItem in interfaces"
                                    :disabled="checkAable(typeItem, infItem)"
                                  >
                                    {{ infItem.channelName }} ({{
                                      infItem.channelAlias
                                    }})
                                  </option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-form-group class="mb-1">
                                <b-input-group append="%" class="mb-2 mr-sm-2">
                                  <b-input
                                    :name="
                                      'tradeRatio_' +
                                      typeItem.payType +
                                      '_' +
                                      index
                                    "
                                    v-model="channelItem.tradeRatio"
                                    placeholder="放量比例"
                                  ></b-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col class="m-0 ml-2 p-0">
                              <b-button
                                v-if="index == 0"
                                title="添加"
                                class="mb-2 mr-sm-1 ml-1"
                                variant="primary"
                                size="sm"
                                v-on:click="addChannelItem(typeItem)"
                              >
                                <b-icon icon="plus"></b-icon>
                              </b-button>

                              <b-button
                                v-else
                                title="删除"
                                class="mb-2 mr-sm-1 ml-1"
                                variant="danger"
                                size="sm"
                                v-on:click="
                                  deleteChannelItem(typeItem, channelItem)
                                "
                              >
                                <b-icon icon="x"></b-icon>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </template>
                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";

import KTUtil from "@/assets/js/components/util";
import FileUpload from "@/view/pages/oms_pages/plugin/FileUpload";
import ApiService from "@/core/services/api.service";
// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "Edit",
  components: {
    KTCard,
    FileUpload,
  },
  data() {
    return {
      interfaces: [],
      formModel: {
        merchantId: null,
        withdrawFee: 0,
        withdrawRate: 0,
        payRuleList: [],
        withdrawConfig: [],
      },
      hideSecret: true,
      hidePwd: false,
    };
  },

  created() {
    let that = this;
    this.formModel.merchantId = this.$route.query.merchantId;
    if (this.editModel) {
      this.getMerchant();
    } else {
      this.getPayTypes();
      this.formModel.withdrawConfig.push({
        minTradeAmount: "",
        maxTradeAmount: "",
        interfaceId: null,
        tradeRatio: "",
      });
    }
    this.getInterfaces();
  },

  mounted() {
    let that = this;
    const payTypeValidator = function () {
      return {
        validate: function (input) {
          // input.element presents the field element
          // input.elements presents all field elements
          // input.field is the field name
          // input.value is the field value
          // Has to return an object containing valid key
          let valid = false;
          let payRuleList = that.formModel.payRuleList;

          for (let i = 0; i < payRuleList.length; i++) {
            let status = payRuleList[i].status;
            if (status == true) {
              valid = true;
              break;
            }
          }

          return {
            valid: valid,
          };
        },
      };
    };

    let merchantForm = KTUtil.getById("merchantForm");
    this.fv = formValidation(merchantForm, {
      fields: {
        merchantName: {
          validators: {
            notEmpty: {
              message: "商户名称不能为空!",
            },
          },
        },
        appId: {
          validators: {
            notEmpty: {
              message: "appId不能为空!",
            },
          },
        },
        publicKey: {
          validators: {
            notEmpty: {
              message: "应用公钥不能为空!",
            },
          },
        },
        privateKey: {
          validators: {
            notEmpty: {
              message: "应用私钥不能为空!",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "通知邮箱不能为空!",
            },
          },
        },
        qq: {
          validators: {
            notEmpty: {
              message: "对账QQ不能为空!",
            },
          },
        },
        telPhone: {
          validators: {
            notEmpty: {
              message: "联系电话不能为空!",
            },
          },
        },
        minAmount: {
          validators: {
            notEmpty: {
              message: "最小付款金额不能为空!",
            },
          },
        },
        maxAmount: {
          validators: {
            notEmpty: {
              message: "最大付款金额不能为空!",
            },
          },
        },

        withdrawChannelId: {
          validators: {
            notEmpty: {
              message: "请选择提现通道!",
            },
          },
        },
        withdrawRate: {
          validators: {
            notEmpty: {
              message: "提现费率不能为空!",
            },
          },
        },

        settleType: {
          validators: {
            notEmpty: {
              message: "请选择结算方式!",
            },
          },
        },
        usdtRate: {
          validators: {
            notEmpty: {
              message: "USDT汇率不能为空!",
            },
          },
        },
        status: {
          validators: {
            notEmpty: {
              message: "状态:1、正常 0、禁用；2、删除不能为空!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
    this.fv.registerValidator("payTypeValidator", payTypeValidator);
    if (!this.editModel) {
      this.fv.addField("loginAccount", {
        validators: {
          notEmpty: {
            message: "用户名不能为空!",
          },
          remote: {
            message: "此登录账号已存在,不可用!",
            method: "POST",
            url: "/api/user/exist",
          },
        },
      });
    }

    let withdrawChannels = this.formModel.withdrawConfig;
    if (withdrawChannels != undefined && withdrawChannels != null) {
      for (let index = 0; index < withdrawChannels.length; index++) {
        this.fv.addField("w_interfaceId_" + index, {
          validators: {
            notEmpty: {
              message: "请选择支付渠!",
            },
          },
        });

        this.fv.addField("w_minTradeAmount_" + index, {
          validators: {
            notEmpty: {
              message: "请输入最小金额!",
            },
          },
        });

        this.fv.addField("w_maxTradeAmount_" + index, {
          validators: {
            notEmpty: {
              message: "请输入最大金额!",
            },
          },
        });

        this.fv.addField("w_tradeRatio_" + index, {
          validators: {
            notEmpty: {
              message: "请输入放量比例!",
            },
          },
        });
      }
    }
  },

  methods: {
    goBack() {
      this.$router.push({
        path: "/merchant/list",
      });
    },
    getPayTypes() {
      this.formModel.payRuleList = [];
      let that = this;
      ApiService.query("/api/merchant/manage/pay_types", {}).then(
        ({ data }) => {
          that.formModel.payRuleList = data;
          if (
            that.formModel.payRuleList != undefined &&
            that.formModel.payRuleList != null
          ) {
            that.formModel.payRuleList.forEach(function (typeItem) {
              that.$nextTick(() => {
                that.fv.addField("status_" + typeItem.payType, {
                  validators: {
                    payTypeValidator: {
                      message: "请至少选择一个支付方式!",
                    },
                  },
                });
              });
            });
          }
        }
      );
    },

    getInterfaces() {
      let that = this;
      ApiService.query("/api/channel/interface/manage/list", {}).then(
        ({ data }) => {
          that.interfaces = data;
        }
      );
    },

    getMerchant() {
      if (
        this.formModel.merchantId != null &&
        this.formModel.merchantId != undefined
      ) {
        let that = this;
        ApiService.query("/api/merchant/manage/query_by_id", {
          params: {
            merchantId: this.formModel.merchantId,
          },
        }).then(({ data }) => {
          if (data.success) {
            this.formModel = data.result;
            if (
              that.formModel.payRuleList != undefined &&
              that.formModel.payRuleList != null
            ) {
              that.formModel.payRuleList.forEach(function (typeItem) {
                that.$nextTick(() => {
                  that.fv.addField("status_" + typeItem.payType, {
                    validators: {
                      payTypeValidator: {
                        message: "请至少选择一个支付方式!",
                      },
                    },
                  });
                });
              });
            }
          }
        });
      }
    },

    generateKey() {
      let that = this;
      ApiService.query("/api/merchant/manage/generate_key", {}).then(
        ({ data }) => {
          that.$set(that.formModel, "appId", data.appId);
          that.$set(that.formModel, "privateKey", data.privateKey);
          that.$set(that.formModel, "publicKey", data.publicKey);
        }
      );
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(that.submitUrl, that.formModel).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.$router.push({
                path: "/merchant/list",
              });
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },

    onReset() {
      let merchantId = this.formModel.merchantId;
      this.formModel = {};
      this.formModel.merchantId = merchantId;
    },

    onPayTypeChange(typeItem) {
      let status = typeItem.status;
      let channels = typeItem.channelConfig;
      let type = typeItem.payType;
      if (status == true) {
        this.fv.resetField("status_" + type, false);
        this.fv.addField("feeRate_" + type, {
          validators: {
            notEmpty: {
              message: "请输入交易费率!",
            },
          },
        });

        if (channels != undefined && channels != null) {
          for (let i = 0; i < channels.length; i++) {
            this.fv.addField("interfaceId_" + type + "_" + i, {
              validators: {
                notEmpty: {
                  message: "请选择支付渠!",
                },
              },
            });

            this.fv.addField("minTradeAmount_" + type + "_" + i, {
              validators: {
                notEmpty: {
                  message: "请输入最小金额!",
                },
              },
            });

            this.fv.addField("maxTradeAmount_" + type + "_" + i, {
              validators: {
                notEmpty: {
                  message: "请输入最大金额!",
                },
              },
            });

            this.fv.addField("tradeRatio_" + type + "_" + i, {
              validators: {
                notEmpty: {
                  message: "请输入放量比例!",
                },
              },
            });
          }
        }
      } else {
        this.fv.resetField("feeRate_" + type, false);
        this.fv.removeField("feeRate_" + type);
        if (channels != undefined && channels != null) {
          for (let i = 0; i < channels.length; i++) {
            this.fv.resetField("interfaceId_" + type + "_" + i, false);
            this.fv.resetField("tradeRatio_" + type + "_" + i, false);
            this.fv.removeField("interfaceId_" + type + "_" + i);
            this.fv.removeField("tradeRatio_" + type + "_" + i);
          }
        }
      }
    },

    addChannelItem(typeItem) {
      let payType = typeItem.payType;
      let index = typeItem.channelConfig.length;
      typeItem.channelConfig.push({
        minTradeAmount: "",
        maxTradeAmount: "",
        interfaceId: null,
        tradeRatio: "",
      });

      this.$nextTick(() => {
        this.fv.addField("interfaceId_" + payType + "_" + index, {
          validators: {
            notEmpty: {
              message: "请选择支付渠!",
            },
          },
        });

        this.fv.addField("minTradeAmount_" + payType + "_" + index, {
          validators: {
            notEmpty: {
              message: "请输入最小金额!",
            },
          },
        });

        this.fv.addField("maxTradeAmount_" + payType + "_" + index, {
          validators: {
            notEmpty: {
              message: "请输入最大金额!",
            },
          },
        });

        this.fv.addField("tradeRatio_" + payType + "_" + index, {
          validators: {
            notEmpty: {
              message: "请输入放量比例!",
            },
          },
        });
      });

      // Vue.set(this.table.desserts, this.table.editIndex, item);
    },
    deleteChannelItem(typeItem, item) {
      let that = this;
      Swal.fire({
        title: "确定删除?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          let index = typeItem.channelConfig.indexOf(item);
          typeItem.channelConfig.splice(index, 1);
          that.fv.removeField(
            "minTradeAmount_" + typeItem.payType + "_" + index
          );
          that.fv.removeField(
            "maxTradeAmount_" + typeItem.payType + "_" + index
          );
          that.fv.removeField("interfaceId_" + typeItem.payType + "_" + index);
          that.fv.removeField("tradeRatio_" + typeItem.payType + "_" + index);
        }
      });
    },

    addWithdrawItem() {
      let index = this.formModel.withdrawConfig.length;
      this.formModel.withdrawConfig.push({
        minTradeAmount: "",
        maxTradeAmount: "",
        interfaceId: null,
        tradeRatio: "",
      });
      this.$nextTick(() => {
        this.fv.addField("w_interfaceId_" + index, {
          validators: {
            notEmpty: {
              message: "请选择支付渠!",
            },
          },
        });

        this.fv.addField("w_minTradeAmount_" + index, {
          validators: {
            notEmpty: {
              message: "请输入最小金额!",
            },
          },
        });

        this.fv.addField("w_maxTradeAmount_" + index, {
          validators: {
            notEmpty: {
              message: "请输入最大金额!",
            },
          },
        });

        this.fv.addField("w_tradeRatio_" + index, {
          validators: {
            notEmpty: {
              message: "请输入放量比例!",
            },
          },
        });
      });

      // Vue.set(this.table.desserts, this.table.editIndex, item);
    },
    deleteWithdrawItem(item) {
      let that = this;
      Swal.fire({
        title: "确定删除?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          let index = this.formModel.withdrawConfig.indexOf(item);
          this.formModel.withdrawConfig.splice(index, 1);
          that.fv.removeField("w_minTradeAmount_" + index);
          that.fv.removeField("w_maxTradeAmount_" + index);
          that.fv.removeField("w_interfaceId_" + index);
          that.fv.removeField("w_tradeRatio_" + index);
        }
      });
    },
    goBack() {
      this.$router.push("/merchant/list");
    },
    checkAable(typeItem, channelInf) {
      let types = channelInf.types;
      let channels = typeItem.channelConfig;
      if (types != undefined && types != null) {
        if (types.indexOf(typeItem.payType) != -1) {
          if (channels != undefined && channels != null) {
            for (let i = 0; i < channels.length; i++) {
              let channel = channels[i];
              if (channel.interfaceId == channelInf.interfaceId) {
                console.info(channelInf.interfaceId);
                return true;
              }
            }
          }
          return false;
        }
      }
      return true;
    },
    checkWithdrawAable(channelInf) {
      let channels = this.formModel.withdrawConfig;
      if (channels != undefined && channels != null) {
        for (let i = 0; i < channels.length; i++) {
          let channel = channels[i];
          if (channel.interfaceId == channelInf.interfaceId) {
            console.info(channelInf.interfaceId);
            return true;
          }
          return false;
        }
      }
      return true;
    },
  },
  computed: {
    submitUrl() {
      return this.formModel.merchantId == null ||
        this.formModel.merchantId == ""
        ? "/api/merchant/manage/create"
        : "/api/merchant/manage/update";
    },
    editModel() {
      return this.formModel.merchantId == null ||
        this.formModel.merchantId == ""
        ? false
        : true;
    },
  },

  watch: {},
};
</script>